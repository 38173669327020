/*
|--------------------------------------------------------------------------
| Transition avec barba et gestion des vues
|--------------------------------------------------------------------------
|
| C'est ici que les transitions entre les pages sont gérées, on y retrouve
| les différentes vues spécifiques à chaque page. Les vues sont également
| définies ici.
*/

import barba from '@barba/core'
import { Accordions } from './classes/accordions.js'
import { inputsAndTextareaLabel, clearInput, fileUpload, select, textareaHeight, ifErrorTrue, formContact, formEmploi, formSearch, calendarInput, timepicker, formExample, formSuccess, resetDropzone, masksDynamicForm, formInfolettre } from './functions/form'
import { clickToScrollToBlock, alerts, calendar, initBackToTop, slugifyProvider, checkInsideComponent, addSvgToLinks  } from './functions/functions.js'
import { forceBlankOnExterneAndPdfLinks, hundredVH, removeHoverOnMobile, sizeH1, sizeTitle } from './functions/helper.js'
import { overlayCalendar, overlayDropdown, overlayFastlinks, overlayMenu, overlayPopup, overlaySearch, overlayShare } from './functions/overlays.js'
import { slickHomeBanner, slickFastLinks, slickHomeFastLinks, slickSimilarEvents, destroySlick, slickAlerts } from './functions/sliders.js'
import { SCROLLFIRE, OVERLAY } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { TyperSetup } from '../libraries/typer.js'

window.barba = barba
window.slugifyProvider = slugifyProvider
window.formSuccess = formSuccess
window.resetDropzone = resetDropzone

export const initBarba = () => {

  // Dans chaque beforeEnter hook
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
    current.container.remove()
    SCROLLFIRE.init()
    hundredVH()
    removeHoverOnMobile()
    TyperSetup()
    forceBlankOnExterneAndPdfLinks()
    slickAlerts()
    alerts()
    overlayPopup()
    overlayShare()
    overlayDropdown()
    overlayMenu()
    overlaySearch()
    overlayFastlinks()
    slickFastLinks()
    inputsAndTextareaLabel()
    clearInput()
    initBackToTop()
    // sizeTitle()
    formSearch('#form-search', '#form-search-submit')
    checkInsideComponent()
    addSvgToLinks()
  })

  // Dans chaque afterLeave hook
  barba.hooks.afterLeave(() => {
    destroySlick()
    OVERLAY.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.init({
    // Options
    prefetchIgnore: true,
    cacheIgnore: false,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    // Transitions de page
    transitions: [
      defaultTransition()
    ],

    views: [

      { // Initialisation des scripts de la feuille de style
        namespace: 'stylesheetView',
        beforeEnter() {
          new Accordions()
          select()
          fileUpload()
          textareaHeight()
          ifErrorTrue()
          sizeH1()
        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page de détail
        namespace: 'detailView',
        beforeEnter() {
          new Accordions()
          select()
          fileUpload()
          textareaHeight()
          ifErrorTrue()
          sizeH1()
          slickSimilarEvents()
          slickHomeFastLinks()
          masksDynamicForm()
          calendarInput()
          timepicker()
          if(document.querySelector('.fileField')) {
            if (typeof $('[data-control=multi-file-uploader]').multiFileUploader == 'function') { //Fix de marde, sinon certaines page crash
              $('[data-control=multi-file-uploader]').multiFileUploader()
            }
          }
        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page d'accueil
        namespace: 'homeView',
        beforeEnter() {
          slickHomeBanner()
          sizeH1()
          slickHomeFastLinks()
          clickToScrollToBlock({ selector: '.js-scroll-to-events', scrollTo: '.js-events-section' })
          formSearch('#form-home-banner', '#form-home-banner-submit')
        },
        afterLeave() {
          destroySlick()
        }
      },

      { // Initialisation des scripts de la page d'évènements
        namespace: 'eventsView',
        beforeEnter() {
          select()
          overlayCalendar()
          calendar()
        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page d'évènements - Détail
        namespace: 'eventsDetailView',
        beforeEnter() {
          slickSimilarEvents()
        },
        afterLeave() {
          destroySlick()
        }
      },

      { // Initialisation des scripts de la page d'actualités
        namespace: 'newsView',
        beforeEnter() {

        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page d'actualités - Détail
        namespace: 'newsDetailView',
        beforeEnter() {

        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page nous joindre
        namespace: 'contactView',
        beforeEnter() {
          new Accordions()
          formContact()
          textareaHeight()
          clickToScrollToBlock({ selector: '.js-scroll-to-write', scrollTo: '.js-write-section' })
          clickToScrollToBlock({ selector: '.js-scroll-to-coords', scrollTo: '.js-coords-section' })
        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page des résultats de recherche
        namespace: 'searchResultsView',
        beforeEnter() {
          formSearch('#form-search-results', '#form-search-results-submit')
        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page des emplois
        namespace: 'jobsView',
        beforeEnter() {
          formEmploi()
          fileUpload()
          textareaHeight()
        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page des emplois - Détail
        namespace: 'jobsDetailView',
        beforeEnter() {
          formEmploi()
          fileUpload()
        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de la page de l'infolettre
        namespace: 'newsletterView',
        beforeEnter() {
          formInfolettre('#form-newsletter', '#submit-newsletter')
        },
        afterLeave() {

        }
      },

      { // Initialisation des scripts de formulaires
        namespace: 'allFormsView',
        beforeEnter() {
          formExample()
          select()
          fileUpload()
          textareaHeight()
          ifErrorTrue()
          calendarInput()
          timepicker()
        },
        afterLeave() {

        }
      },
  ]
  })
}
